html,
body {
  overflow-y: hidden;
  height: 100%;
  overflow: hidden;
}

body #crisp-chatbox > div > a {
  bottom: 54px !important;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #9ea7b6;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
